<template>
  <div class="login-content">
    <h1 class="title is-1">Manage things</h1>
    <section class="mt-6">
      <h5 class="title is-5">Make user an admin</h5>
      <b-field >
        <b-input
          v-model="adminEmail"
          type="email"
          icon="account"
          placeholder="Admin Email"
           />
      </b-field>
      <b-field grouped >
        <b-button class="is-flex-grow-1 mr-1" type="is-danger" @click="updateAdmin(false)">Remove Admin</b-button>
        <b-button class="is-flex-grow-5 ml-1" type="is-primary" @click="updateAdmin(true)">Make Admin</b-button>
      </b-field>
    </section>
    <section class="mt-6">
      <h5 class="title is-5">Data management</h5>
      <b-field label="Sync all de-normalised recipe data on mealplans">
        <b-button expanded class="is-flex-grow-1 mr-1" type="is-primary" @click="syncRecipeData($event)">Sync Recipe Data</b-button>
      </b-field>
      <br />
      <b-field label="Add property to all documents of a collection">
        <b-button expanded class="is-flex-grow-1 mr-1" type="is-primary" @click="addProperty($event)">Add property with default value</b-button>
      </b-field>
    </section>
  </div>
</template>

<script>
import db from "@/firebase/db"
import functions from "@/firebase/functions"

export default {
  name: 'ManageView',
  data() {
    return {
      adminEmail: '',
    };
  },
  methods: {
    updateAdmin(admin) {
      const updateAdminRole = functions.httpsCallable("updateAdminRole");
      updateAdminRole({email: this.adminEmail, admin: admin})
        .then(result => {
          this.adminEmail = '';
          this.$buefy.toast.open({type: 'is-success', message: `${result.data.message}`});
        }).catch(error => {
          this.$buefy.toast.open({type: 'is-danger', message: `${error}`});
        });
    },
    syncRecipeData() {
      this.$buefy.toast.open({type: 'is-dark', message: "🤖 Beep bop boop - syncing recipe data. This may take some time..."});
      const syncRecipeData = functions.httpsCallable("syncRecipeData");
      syncRecipeData()
        .then(result => {
          this.$buefy.toast.open({type: 'is-success', message: `${result.data.message}`, duration: 4000});
        }).catch(error => {
          this.$buefy.toast.open({type: 'is-danger', message: `${error}`, duration: 4000});
        });
    },
    addProperty() {

      // this.$buefy.toast.open({type: 'is-warning', message: `Disabled for now! Follow the instructions in Manage.vue to enable`});
      // return;

      // 1. update the collection you want to change!
      let collectionName = "recipes";

      // 2. Update the property name and default value
      let propertyName = "is_free";
      let propertyValue = false;

      db.collection(collectionName).get().then(snapshot => {
        if (snapshot.docs == null){
          this.$buefy.toast.open({type: 'is-warning', message: `No ${collectionName}`});
          return;
        }

        const entry = {};
        entry[`${propertyName}`] = propertyValue;
        
        // keeps the id for later!
        let docs = snapshot.docs.map(docSnapshot => {
          let doc = docSnapshot.data();
          doc.id = docSnapshot.id;
          return doc;
        });

        // 
        console.log(`${docs.length}`);
        docs.forEach(doc => {
          
          // 3. update the null check
          if (doc[`${propertyName}`] == null){
            console.log(doc.id);
            db.collection(`${collectionName}`).doc(doc.id)
              .set(entry, {merge: true})
              .then(() => {
                console.log(`doc in ${collectionName} updated!`);
              })
              .catch((error) => {
                  console.error("Error updating doc: ", error);
              });
          }
        });
      });

      // How to use:
      // 0. Be SUPER careful here! Test on STAGING FIRST!
      // 1. update the collection name
      // 2. Set the property and default value.
      // 3. Update the null check
      // 4. remove return above. 

      // 1. update the collection you want to change!
      // let collectionName = "recipes";

      // // 2. Update the property name and default value
      // let propertyName = "is_variant";
      // let propertyValue = false;

      // db.collection(collectionName).get().then(snapshot => {
      //   if (snapshot.docs == null){
      //     this.$buefy.toast.open({type: 'is-warning', message: `No recipes`});
      //     return;
      //   }

      //   const entry = {};
      //   entry[`${propertyName}`] = propertyValue;
        
      //   // keeps the id for later!
      //   let docs = snapshot.docs.map(docSnapshot => {
      //     let doc = docSnapshot.data();
      //     doc.id = docSnapshot.id;
      //     return doc;
      //   });

      //   // 
      //   docs.forEach(doc => {
          
      //     // 3. update the null check
      //     if (doc[`${propertyName}`] == null){
      //       console.log(doc.id);
      //       db.collection(`${collectionName}`).doc(doc.id)
      //         .set(entry, {merge: true})
      //         .then(() => {
      //           console.log("doc updated!");
      //         })
      //         .catch((error) => {
      //             console.error("Error updaing doc: ", error);
      //         });
      //     }
      //   });
      // });
    }
  }
}
</script>

<style>
.login-content {
  margin: 10% auto;
  width: 50%;
}
</style>
